import React from 'react';
import getstarted from '../views/GetStarted/GetStarted.jsx';

const GetStartedContainer = (props) => {
    return (
        <getstarted/>
    );
};

export default GetStartedContainer;
