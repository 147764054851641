import React from 'react';
import Calendar from '../views/Calendar/calendar.jsx';


const CalendarContainer = () => {
    return (
        <Calendar/>
    );
};

export default CalendarContainer;
