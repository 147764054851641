import React from 'react';
import Calendar3 from '../views/Calendar/calendar3.jsx';

const CalendarContainer3 = () => {
    return (
        <Calendar3/>
    );
};

export default CalendarContainer3;
