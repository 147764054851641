import React from 'react';
import Calendar2 from '../views/Calendar/calendar2.jsx';

const CalendarContainer2 = () => {
    return (
        <Calendar2/>
    );
};

export default CalendarContainer2;
